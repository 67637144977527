<template>
    <div class="justify-content-center align-items-center w-100 px-1">
        <header class="jumbotron">
            <div class="inline-flex">
                <h3>
                    <strong>Recent Daily Reports </strong>
                </h3>
                <div class="float-right"><button type="button" class="btn btn-dark" data-toggle="modal" @click="createReport()">Create New Daily Report</button></div>
            </div>
        </header>
        <table class="table">
            <thead>
                <tr>
                    <th scope="col">Day</th>
                    <th scope="col">Food Sales</th>
                    <th scope="col">Liqour Sales</th>
                    <th scope="col">Labour</th>
                    <th scope="col">Submitted By</th>
                    <th scope="col">Edit</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(report,index) in report_list" :key="index">
                    <td>{{dateClean(report.report_date)}} {{report.report_date}}</td>
                    <td>${{report.meta_daily.sales.food ? report.meta_daily.sales.food : '0'}}</td>
                    <td>${{report.meta_daily.sales.liqour ? report.meta_daily.sales.liqour : '0'}}</td>
                    <td>${{getLabourTotal(report.meta_daily.staff_list)}}</td>   
                    <td>{{userResolver(report.user_id)}}</td>  
                    <td class="justify-content-center align-items-center"><font-awesome-icon icon="user-edit" @click="resolveEditor(report.id)"/></td>
                </tr>
            </tbody>
        </table>
            <div class="background-form-group p-4">
                <h3 class="mb-2">Edit By Date:</h3>
                <div class="p-2 inputWhiteDiv form-row align-items-center">
                    <div class="green-border-focus col-auto">
                <input type="date" class="form-control" id="inputhours" v-model="dateFinder"></div>
                <div class="col-auto">
                <button type="button" class="btn btn-dark mb-6 px-1" @click="findReport()">Edit Date</button>
            </div>
                    </div>
                    <p style="color:red" v-if="dateFinderError">{{dateFinderError}}</p>
            </div>
    </div>
</template>
<script>
import UserService from '../services/user.service';

export default {
    name: 'Staff',
    data() {
        return {
            roles: [],
            positions: [],
            areas: [],
            restaurants: [],
            report_list:[],
            users:[],
            dateFinder:null,
            dateFinderError:null
        };
    },
    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        }
    },
    methods: {
        dateClean(date){
            date = new Date(Date.parse(date));
            let datenum = date.getUTCDay()
            return ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'][datenum]
        },
        salesTotal(sales){
            return sales.liqour + sales.food
        },
        resolveEditor(id){
            this.$router.push(`/reporting/edit/${id}`);
        },
        getLabourTotal(staff){
            return staff.reduce((accum, item) => accum + (Number(item.dailyHours) * Number(item.wage) + Number(item.dailyOT) * Number(item.wage) * 1.5 + Number(item.dailyStat) * Number(item.wage) * 1.5), 0)
        },
        createReport(id){
            this.$router.push(`/reporting/daily`);
        },
        findReport(){
            if(this.dateFinder == null || this.dateFinder == undefined){
                this.dateFinderError = "Please Select a Date."
            }
            else{
            this.dateFinderError = null
            UserService.getReportByDate(this.dateFinder).then(
            response => {
                console.log(response.data)
                if(response.data == 'none'){
                    this.dateFinderError = "No Report Found For Date!"
                }
                else{
                    this.resolveEditor(response.data.id)
                }
            },
            error => {
                this.content =
                    (error.response && error.response.data && error.response.data.message) ||
                    error.message ||
                    error.toString();
            }
        );
        }
        },
        idToPosition(id) {
            if (this.positions.length > 0) {

                return this.positions.filter(function(el) {
                    return el.id == id
                })[0].name
            } else {
                return ''
            }
        },
        idToRestaurant(id) {
            if (this.restaurants.length > 0) {
                return this.restaurants.filter(function(el) {
                    return el.id == id
                })[0].name
            } else {
                return ''
            }
        },
        userResolver(id) {
            if (this.users.length > 0) {
                let name = this.users.filter(function(el) {
                    return el.id == id
                })[0]
                return `${name.first_name} ${name.last_name}`
            } else {
                return ''
            }
        },
    },
    mounted() {
        if (!this.currentUser) {
            this.$router.push('/login');
        }
        UserService.getRecentReports().then(
            response => {
                this.report_list = response.data;
            },
            error => {
                this.content =
                    (error.response && error.response.data && error.response.data.message) ||
                    error.message ||
                    error.toString();
            }
        );
        UserService.getUsersNames().then(
            response => {
                this.users = response.data;
            },
            error => {
                this.content =
                    (error.response && error.response.data && error.response.data.message) ||
                    error.message ||
                    error.toString();
            }
        );
        UserService.getPositionList().then(
            response => {
                this.positions = response.data;
            },
            error => {
                this.content =
                    (error.response && error.response.data && error.response.data.message) ||
                    error.message ||
                    error.toString();
            }
        );
        UserService.getRestaurantsList().then(
            response => {
                this.restaurants = response.data;
            },
            error => {
                this.content =
                    (error.response && error.response.data && error.response.data.message) ||
                    error.message ||
                    error.toString();
            }
        );
    }
};
</script>
<style scoped>
    .background-form-group {
    background: #E8E8E8;
    border-radius: 8px;
}

.table {
    border-radius: 10px;
    table-layout: fixed;
}

.inputWhiteDiv {
    border-radius: 10px;
    background-color: white;
}
</style>